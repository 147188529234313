














































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState("assets", {
      images: "images",
      oss: "oss"
    }),
    ...mapState("app", {
      language: "language"
    })
  },
  methods: {
    contactUs() {
      dispatch.contactUs();
    },
    onDownload(url: string) {
      download(url);
    }
  }
});
